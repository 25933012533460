/*eslint-disable */
<template>
  <div class="questionContainer p-2 m-2">
    <div class="row questionRow m-0 w-100 p-4">
      <div class="col-md-12 col-lg-12 col-sm-12 que">
        <div class="sidebarContainer m-2">
          <!-- title -->
          <span v-if="queData.question">
            <h5 class="mb-2">
              <span style="font-weight: 500;" class="">Question Id: <span class="primary-color">{{queData.qbk_id}}</span></span>
              <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="quizBankEdit(queData.qbk_id)" class="pull-right ml-2" title="Edit Question">
                <i class="fa fa-edit font-size-20 primary-color pointer" aria-hidden="true"></i>
              </span>
              <!-- images -->
              <span v-if="queData.image" @click="imgModal(queData.image)" class="magnifying_glass">
                <img class="que_img" :src="queData.image" />
              </span>
              <span v-if="queData.image" class="pull-right ml-2" title="Image Zoom">
                <i @click="imgModal(queData.image)" class="fa fa-search-plus primary-color font-size-20" aria-hidden="true"></i>
              </span>
              <span v-if="queData.question && queData.question.includes('<img src')" class="pull-right ml-2" title="Image Zoom">
                <i @click="imgModal(queData.question)" class="fa fa-search-plus primary-color font-size-20" aria-hidden="true"></i>
              </span>
              <span v-if="queData.has_calc" @click="showCalculatorModal= !showCalculatorModal" class="pull-right ml-2" title="Desmos Calculator">
                <i class="fa-solid fa-calculator font-size-20 primary-color" aria-hidden="true"></i>
              </span>
            </h5>
            <h5 class="mt-2"><span class="title is-2 questionTag vhtmlUlLi" v-html="queData.question"></span></h5>
          </span>
        </div>
      </div>

      <div class="col-md-12 col-lg-12 col-sm-12 questionBox mt-2">
        <div class="questionContainer">
          <!-- quizOptions -->
          <div class="optionContainer mt-2" :key="update">
            <template v-if="queData.is_mcq == 1">
              <div
                class="option"
                v-for="(choice, optionNo) in queData.answer_choices"
                @click="selectOption(optionNo)"
                :class="{ 'is-selected': userResponses[queData.qbk_id] && userResponses[queData.qbk_id][optionNo] }"
                :key="optionNo"
              >
                <span class="optionNo">
                  <span class="capitalize mr-2">
                    {{ optionNo}}
                  </span>
                </span>
                <span v-html="choice"></span>
              </div>
              <div class="questionFooter mt-5">
                <h5 style="font-weight: 500;">Correct Answer : <span class="success-color" style="text-transform: capitalize;"> {{ queData.correct_answers }} </span> </h5>
              </div>
              <!-- Answer Explanation -->
              <div class="questionFooter mt-5">
                <h5 style="font-weight: 500;">Explanation:
                  <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="solveQuizBankViaGPT" class="pull-right" title="Solve Question via AI">
                    <i class="fa fa-quora font-size-20 primary-color pointer" aria-hidden="true"></i>
                  </span>
                </h5>
                <span class="primary-color katex" v-html="queData.answer_explanation"></span>
              </div><!-- Answer Explanation -->
            </template>
            <template v-else>
              <div class="questionFooter mt-3">
                <h5 style="font-weight: 500;">Correct Answer : <span class="primary-color" style="text-transform: capitalize;">This is a non MCQ type question. Hence no choices were provided</span></h5>
              </div>
              <h5 style="font-weight: 500;" class="mt-3">Correct Answers : <span class="primary-color" style="text-transform: capitalize;"> {{ queData.correct_answers }} </span> </h5>
              <!-- Answer Explanation -->
              <div class="questionFooter mt-3">
                <h5 style="font-weight: 500;">Explanation:
                  <button v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="solveQuizBankViaGPT" class="" title="Solve Question via AI">
                    <i class="fa fa-quora font-size-20 primary-color pointer" aria-hidden="true"></i>
                  </button>
                </h5>
                <span class="primary-color katex" v-html="queData.answer_explanation"></span>
              </div><!-- Answer Explanation -->
            </template>
          </div>
        </div>
        <!--/questionContainer-->

      </div>
    </div>

    <b-modal id="DemosCal" style="width:100%" v-model="showCalculatorModal">
      <template #modal-header="">
        <h4 class=" mb-1 instituteTitle_block">
          Demos Calculator
        </h4>
      </template>
      <iframe style="height: 70vh; width: 100%;" :src="desmosCalc">
      </iframe>
    </b-modal>

    <b-modal
      v-model="showModelImageView"
      name="Modal"
      scrollable
      size="xl"
      >
      <image-view :propImageUrl="propImageUrl" :propImg="propImg"/>
    </b-modal>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import ImageView from "./ImageView.vue"
import { base64decode } from "nodejs-base64"
import "katex/dist/katex.min.css"
import katex from "katex"

export default {
  name: "QuestionQuiz",
  components: {
    ImageView
  },
  data () {
    return {
      apiName: "quiz_bank_view",
      questionIndex: 0,
      queData: [],
      userResponses: {},
      quizStarted: true,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Quiz Response",
      update: 0,
      qbk_id: null,
      showCalculatorModal: false,
      desmosCalc: "https://www.desmos.com/calculator?lang=en",
      propImageUrl: null,
      propImg: null,
      showModelImageView: false
    }
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propQuestionBankObj: {
      type: Object,
      default: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    window.katex = katex

    if (this.$route.params.qbk_id) {
      this.qbk_id = this.$route.params.qbk_id
    }

    if (this.propQuestionBankObj && this.propQuestionBankObj.qbk_id) {
      this.qbk_id = this.propQuestionBankObj.qbk_id
    }
    this.quizBankView()
  },
  methods: {
    /**
     * quizBankView
     */
    async quizBankView () {
      try {
        let quizBankViewResp = await QuizBanks.quizBankView(this, this.qbk_id)
        if (quizBankViewResp && quizBankViewResp.resp_status) {
          this.queData = quizBankViewResp.resp_data.data

          // Quiz Images
          if (this.queData.image) {
            this.vmCoverPic = this.queData.image
          }

          if (this.queData.answer_explanation) {
            var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
            this.queData.answer_explanation = base64regex.test(this.queData.answer_explanation) ? base64decode(this.queData.answer_explanation) : this.queData.answer_explanation
            /*
             * For Future Use to detect LaTex Script. ~ => Space, //=> Newline.
             * Currently can't enter multiple new lines as Katex does not recognise it
             * IS_LATEX_RESPONSE needs to be added in the GPT Response
             */
            let answerExplanationLatex = ""
            if (this.queData.answer_explanation.includes("IS_LATEX_RESPONSE")) {
              this.queData.answer_explanation = this.queData.answer_explanation.replace("IS_LATEX_RESPONSE", "")
              answerExplanationLatex = this.queData.answer_explanation.replace(/ /g, "~").replace(/\\\[/g, "").replace(/\\\]/g, "").replace(/\\\(/g, "").replace(/\\\)/g, "").replace(/\n/g, "\\")
              answerExplanationLatex = katex.renderToString(answerExplanationLatex, { throwOnError: false })
              this.queData.answer_explanation = answerExplanationLatex
            }

            this.queData.answer_explanation = this.queData.answer_explanation.replace("IS_LATEX_RESPONSE", "")
            this.queData.answer_explanation = this.queData.answer_explanation.replace(/\n/g, "<br/>")
          }

          this.queData.qbk_category_option = this.queData.qbk_category
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankView() and Exception:", err.message)
      }
    },
    /**
     * imgModal
     */
    imgModal (quesObj) {
      try {
        this.propImageUrl = quesObj
        this.showModelImageView = true
      }
      catch (err) {
        console.error("Exception occurred at ImageView() and Exception:", err.message)
      }
    },
    /**
     * solveQuizBankViaGPT
     */
    async solveQuizBankViaGPT (questionData) {
      try {
        const solveObj = {
          qbk_id: this.queData.qbk_id,
          question: this.queData.question,
          answer_choices: JSON.stringify(this.queData.answer_choices),
          image: this.queData.image
        }
        let quizSolveResp = await QuizBanks.quizBankSolve(this, solveObj)
        if (!quizSolveResp.resp_status) {
          this.toastMsg = quizSolveResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        this.toastMsg = quizSolveResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
      }
      catch (err) {
        console.error("Exception in solveQuizBankViaGPT and err: ", err)
      }
    },
    /**
     * quizBankEdit
     */
    quizBankEdit (qbkId) {
      window.open("/quizBank_edit/" + qbkId, "_blank")
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  /* mocking native UI */
  cursor: default !important; /* remove text selection cursor */
  user-select: none; /* remove text selection */
  user-drag: none; /* disbale element dragging */
}
.title, .subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
}
.title {
  font-size: 18px !important;
  margin-top: 5px !important;
}
.animated {
  transition-duration: 0.3;
}
.que {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  padding-top: 10px;
}
.questionRow {
  background: white;
  border: 1px solid #f1f1f1;
}
.questionBox {
  padding-top: 0px;
  padding-bottom: 10px;
  .quizForm {
    display: block;
    white-space: normal;
    .quizFormContainer {
      height: 100%;
      margin: 15px 18px;
      padding: 10px;
      .field-label {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }
  .quizCompleted {
    width: 100%;
    padding: 25px;
  }
  .questionContainer {
    white-space: normal;
    height: 100%;
    width: 100%;
  .optionContainer {
      margin-top: 0px;
      flex-grow: 1;
      .option {
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 5px 10px 5px 50px;
        cursor: pointer;
        border: transparent 1px solid #f1f1f1;
        position: relative;
        border: 1px solid #f1f1f1;
        overflow: hidden;
        font-size: 18px;
        &.is-selected {
          border-color: var(--iq-primary);
          background-color: white;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .questionFooter {
      width: 100%;
      align-self: flex-end;
      .pagination {
        //padding: 10px 15px;
        margin: 15px 25px;
      }
      .progressContainer {
        // margin: 15px 25px;
      }
    }
  }
}
h3.tag{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  .duration{
    float: right;
    font-size:18px;
  }
}
.tags h3{
  font-size:18px;
}
.progress{
  height: 0.5rem;
}
@media screen and (min-width: 769px) {
  .container {
    height: 100%;
    .columns {
      height: 100%;
      .column {
        height: 100%;
      }
    }
  }
  .questionBox {
    align-items: center;
    justify-content: center;
    padding: 20px;
    .questionContainer {
      display: flex;
      flex-direction: column;
    }
  }
  .que {
    // box-shadow: inset rgb(255 255 255) -20px 0px 20px 0px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .questionFooter{
    button{
      margin: auto;
      margin-bottom:5px;
    }
  }
}
.next{
  float:right;
}
::-webkit-progress-bar {
  background-color: #f1f1f1;
}
.optionNo {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 40px;
  background-color: #f1f1f1;
  left: 0px;
}
.optionNo span {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  width: 17px;
  height: 23px;
  font-weight: 500;
  line-height: initial;
  font-size: 18px;
}
.questionTag {
  white-space: pre-wrap;
  img{
    max-height: 250px;
  }
}
.que_img{
  height: auto;
  width: 200px;
  display: block;
}
</style>
